import React from "react";

const items = [
  {
    title: "Արդար Հայ Եմ",
    description: `
Արդարությունը դառնալու է ապագա աշխարհակարգի հիմքը։ Արդարությունը ներկայի պահանջն է ու ապագայի սպասումը։ Այդպես է կամենում նաև հայը, արդար հայը։ Բայց մինչ այդ արդարություն պետք է հաստատվի մեր մտքերում, սրտերում ու հոգիներում, ընտանիքներում, մեր հանրային ու պետական կյանքում և համայն հայության միջավայրում։

«Արդար Հայ Եմ» նախագիծը այդ արդարությանը հասնելու երկար ճանապարհի առաջին փոքր, բայց հաստատուն քայլն է։`,
  },
];

const Banner = () => {
  return (
    <div className="mb-16 mt-8 px-10 md:px-20">
      {items.map((item, index) => {
        return (
          <div key={index}>
            <span className="font-bold text-[18px]">{item.title}</span>
            <p className="mt-2 whitespace-pre-wrap">{item.description}</p>
            <ul className="mt-2 list-disc list-inside">
              {item.subItems?.map((subItem, index) => {
                return <li key={index}>{subItem}</li>;
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Banner;
