import * as React from "react";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Link from "next/link";
import { useRouter } from "next/router"; // Import useRouter

import { CiShare2 } from "react-icons/ci";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";

export default function SocialShare() {
  const router = useRouter(); // Initialize useRouter
  const currentUrl = encodeURIComponent(`https://ardarhay.am/${router.asPath}`); // Get the current URL from the router
  const message = encodeURIComponent("Check this out!");

  const instagramShareUrl = `https://instagram.com/direct/inbox/?text=${message}&url=${currentUrl}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${message}&url=${currentUrl}`;

  const actions = useMemo(
    () => [
      {
        icon: (
          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
            target="_blank"
          >
            <FaSquareFacebook size={24} />
          </Link>
        ),
        name: "Facebook",
      },
      {
        icon: (
          <Link href={twitterShareUrl} target="_blank">
            <FaSquareTwitter size={24} />
          </Link>
        ),
        name: "Twitter",
      },
    ],
    [currentUrl, instagramShareUrl, twitterShareUrl]
  );

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<CiShare2 size={26} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
