import React from "react";
import Slider from "../slider/Slider";

const ProgramsContainer = ({ programs, onClick }) => {
  return (
    <div className="mt-28 w-full">
      <Slider items={programs} onClick={onClick} />
    </div>
  );
};

export default ProgramsContainer;
