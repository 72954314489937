import React, { useEffect, useRef, useState } from "react";

const YouTubePlayer = ({
  videoUrl,
  onPlayerStateChange = () => {},
  onReady = () => {},
}) => {
  const playerRef = useRef(null);
  const [player, setPlayer] = useState(null);

  // Initialize the player once the API is loaded
  const initializePlayer = () => {
    if (playerRef.current && videoUrl) {
      const newPlayer = new window.YT.Player(playerRef.current, {
        videoId: getVideoId(videoUrl),
        playerVars: {
          height: "100%",
          width: "100%",
          fs: 0, // fullscreen button enabled
          rel: 0, // disable related videos at the end
        },
        events: {
          onReady,
          onStateChange: (event) => {
            onPlayerStateChange(event.data);
          },
        },
      });
      setPlayer(newPlayer);
    }
  };

  useEffect(() => {
    // Check if YT is already loaded
    if (window.YT) {
      initializePlayer();
    } else {
      // Load the YouTube Iframe API script dynamically
      const script = document.createElement("script");
      script.src = "https://www.youtube.com/iframe_api";
      script.onload = () => {
        window.onYouTubeIframeAPIReady = initializePlayer;
      };
      document.body.appendChild(script);
    }

    return () => {
      if (player) {
        player.destroy(); // Cleanup player on component unmount
      }
    };
  }, [videoUrl]);

  // Extract videoId from the YouTube URL
  const getVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/?|(?:.*[?&]v=))([^"&?\/\s]{11}))/;
    const match = url.match(regex);
    return match ? match[1] : "";
  };

  return <div ref={playerRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default YouTubePlayer;
